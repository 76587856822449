import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { emblemOnly, both, variant } = props;

  const size = '30px';

  const logo = variant === 'light' ? '/static/mei_logo_light.png' : '/static/mei_logo_dark.png';
  const icon = variant === 'light' ? '/static/mei_icon_light.png' : '/static/mei_icon_dark.png';

  if (both) {
    return (
      <>
        <Box
          component="img"
          src={icon}
          height={size}
          alt="Gigantic Duck MEI Icon"
          sx={{ mr: 1 }}
        />
        <img
          src={logo}
          height={size}
          alt="Gigantic Duck MEI Logo"
        />
      </>
    );
  }
  if (emblemOnly) {
    return (
      <img
        src={icon}
        width={size}
        alt="Gigantic Duck MEI Icon"
      />
    );
  }
  return (
    <img
      src={logo}
      width={size}
      alt="Gigantic Duck MEI Logo"
    />
  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark',
  both: false
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  both: PropTypes.bool
};
