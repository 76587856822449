import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Box, Button, Divider, IconButton, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSettings } from '../contexts/settings-context';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { Logo } from './logo';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { useEnvironment } from '../hooks/use-environment';
import { EnvironmentPopover } from './environment/environment-popover';

export const DashboardNavbar = () => {
  const { change, environment, environments } = useEnvironment();

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');
  const [currentEnvironment, setCurrentEnvironment] = useState();

  const handleSwitchTheme = () => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light'
    });

    setDarkMode(settings.theme === 'light');
  };

  const handleEnvironmentChange = (environmentId) => {
    const newEnvironment = environments.find((env) => env.id
      === environmentId);

    if (!newEnvironment) {
      return;
    }

    // Set Current Environment
    setCurrentEnvironment(newEnvironment);
    // Set Environment From Hook
    change(newEnvironment);
  };

  useEffect(() => {
    // Get Current Environment
    setCurrentEnvironment(environment);
  }, [environment]);

  return (
    <AppBar
      elevation={0}
      sx={{ backgroundColor: 'neutral.100' }}
    >
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,
          px: 3,
          py: 1
        }}
      >
        <Box
          component={RouterLink}
          to={`/dashboard/${currentEnvironment ? currentEnvironment.id : ''}`}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Logo
            emblemOnly
            variant={darkMode ? 'dark' : 'light'}
            both
          />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3
          }}
        />
        <EnvironmentPopover
          currentEnvironment={currentEnvironment}
          onEnvironmentChange={handleEnvironmentChange}
          environments={environments}
          darkMode={darkMode}
          sx={{
            display: {
              md: 'flex',
            }
          }}
        />
        <DashboardNavbarMenu
          onClose={() => setOpenMenu(false)}
          open={mdDown && openMenu}
        />
        <Button
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex'
            }
          }}
          variant="text"
        >
          {
            openMenu ? <CloseIcon /> : <MenuIcon />
          }
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 2,
            display: {
              md: 'inline-flex',
              xs: 'none'
            }
          }}
        >
          {darkMode
            ? <SunIcon />
            : <MoonIcon color="primary" />}
        </IconButton>
        <AccountPopover
          darkMode={darkMode}
          onSwitchTheme={handleSwitchTheme}
        />
      </Toolbar>
    </AppBar>
  );
};
