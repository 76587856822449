import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  Switch,
  ListSubheader
} from '@material-ui/core';
import { useAuth } from '../hooks/use-auth';
import { useEnvironment } from '../hooks/use-environment';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { Logout as LogoutIcon } from '../icons/logout';
import { User as UserIcon } from '../icons/user';
import { lightNeutral } from '../colors';
import { UserAvatar } from './user-avatar';

export const AccountPopover = (props) => {
  const {
    darkMode,
    onSwitchTheme,
    ...other
  } = props;
  const navigate = useNavigate();
  const { logout, user, accessToken } = useAuth();
  const { firstName, lastName, image } = user ?? {};
  const [anchorRef, open, handleOpen, handleClose] = usePopover();
  const { environment } = useEnvironment();

  // Get Current Role
  const result = user.roles.find((r) => {
    if (environment) return +r.environmentId === +environment.id;
    return false;
  });

  let role;
  if (result) role = result.role;
  if (user.isSuperAdmin) role = { name: 'Superadmin' };

  // Image To Show
  let imageToShow;
  if (image) imageToShow = `${process.env.REACT_APP_STATIC_SERVER}/${image}`;
  else imageToShow = '/static/user-random.png';

  const handleLogout = async () => {
    try {
      handleClose();
      await logout(accessToken);
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2
        }}
        {...other}
      >
        <UserAvatar
          image={image}
          width={40}
          height={40}
        />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            },
            flex: 1,
            ml: 1,
            minWidth: 120
          }}
        >
          <div>
            <Typography
              sx={{
                color: lightNeutral[500]
              }}
              variant="caption"
            >
              {role?.name ?? 'My Role'}
            </Typography>
            <Typography
              sx={{ color: darkMode ? 'primary.contrastText' : lightNeutral[900] }}
              variant="subtitle2"
            >
              { `${firstName} ${lastName}` }
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: 'primary.contrastText',
              ml: 1
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <List>
          <ListItem divider>
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                src={imageToShow}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${firstName} ${lastName}`}
              secondary={role?.name ?? 'My Role'}
            />
          </ListItem>
          <li>
            <List disablePadding>
              <ListSubheader disableSticky>
                Control Panel
              </ListSubheader>
              <ListItem
                sx={{
                  py: 0,
                  display: {
                    md: 'none',
                    xs: 'flex'
                  }
                }}
              >
                <Switch
                  checked={darkMode}
                  onChange={onSwitchTheme}
                />
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Dark Mode
                </Typography>
              </ListItem>
            </List>
          </li>
          <ListItem
            button
            component={RouterLink}
            divider
            onClick={handleClose}
            to={`/dashboard/${environment ? environment.id : 0}/account`}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  darkMode: PropTypes.bool.isRequired,
  onSwitchTheme: PropTypes.func.isRequired,
};
