import { createContext, useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { environmentApi } from '../api/environment';
import { useAuth } from '../hooks/use-auth';

const initialEnvironment = null;
const initialEnvironments = [];

export const EnvironmentContext = createContext({
  environment: initialEnvironment,
  change: () => {},
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  environments: initialEnvironments
});

export const EnvironmentProvider = ({ children }) => {
  const { user, accessToken } = useAuth();

  const [environment, setEnvironment] = useState(initialEnvironment);
  const [environments, setEnvironments] = useState(initialEnvironments);

  const navigate = useNavigate();
  const location = useLocation();

  // EnvironmentId
  let environmentId;
  let restPath;
  const match = matchPath({ path: '/dashboard/:environmentId/*', strict: false }, location.pathname);
  if (match) ({ environmentId, '*': restPath } = match.params);

  const change = (e) => {
    if (!e) return;

    let destination;
    if (environmentId) {
      // Desitnation
      destination = `/dashboard/${e.id}/${restPath}`;
    } else {
      // Destination
      destination = `/dashboard/${e.id}/`;
    }

    // Navigate
    navigate(destination);
  };

  const create = async ({
    file:
    image,
    name,
    tag,
    description,
    identifier,
    secretKey,
    gdApiUrl,
    gdApiSecret
  }) => {
    try {
      const data = await environmentApi.create({
        image,
        name,
        tag,
        description,
        identifier,
        secretKey,
        gdApiUrl,
        gdApiSecret
      }, accessToken);
      const { image: dataImage, environment: dataEnvironment } = data;
      // Update Environment And Include Image
      if (dataImage) setEnvironment({ ...dataEnvironment, image: dataImage });
      else setEnvironment({ ...dataEnvironment });
      // Return Data
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const update = async ({
    file: image,
    name,
    tag,
    description,
    identifier,
    gdApiUrl,
    gdApiSecret
  }) => {
    // Get ID
    const { id } = environment;
    try {
      const data = await environmentApi.update({
        image,
        id,
        name,
        tag,
        description,
        identifier,
        gdApiUrl,
        gdApiSecret
      }, accessToken, environment.id);
      const { image: dataImage, environment: dataEnvironment } = data;
      // Update Environment And Include Image
      if (dataImage) setEnvironment({ ...dataEnvironment, image: dataImage });
      else setEnvironment({ ...dataEnvironment });
      // Return Data
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const buildEnvironments = (envs) => {
    if (!envs) return [];
    // New ENV array
    const newEnvs = [];
    if (!envs || envs.length <= 0) return [];
    envs.forEach((env) => {
      const { id, name, tag, description, identifier, data, gdApiSecret, gdApiUrl } = env;
      let image;
      if (data.length > 0) image = data[0].value;
      // Build new ENV to include Image
      const build = { id, name, tag, description, identifier, image, gdApiSecret, gdApiUrl };
      // Push Newly Build ENV
      newEnvs.push(build);
    });
    // Return
    return [...newEnvs];
  };

  // Set Environment With Environment ID
  useEffect(() => {
    if (environments && environmentId) {
      const env = environments.find((e) => e.id === +environmentId);
      if (!environment) {
        setEnvironment(env);
        return;
      }
      if (env.id !== environment.id) {
        setEnvironment(env);
        return;
      }
    }
    setEnvironment(null);
  }, [environmentId, environments]);

  useEffect(async () => {
    // Check For User
    if (!user) return;

    // Check For Access Token
    if (!accessToken) return;

    if (environments.length > 0) return;

    // Get Environments
    const { environments: data } = await environmentApi.getAll(accessToken);

    // Set Environments
    setEnvironments(() => buildEnvironments(data));
  }, [accessToken, user]);

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        change,
        create,
        update,
        environments
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

EnvironmentProvider.propTypes = {
  children: PropTypes.node.isRequired
};
