import axios from 'axios';

const ServerApiOptions = {
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
  params: {
    source: 'mei'
  }
};

const GDApiOptions = {
  baseURL: process.env.REACT_APP_GDG_SERVER
};

export const gdAxios = axios.create(GDApiOptions);
export const serverAxios = axios.create(ServerApiOptions);
