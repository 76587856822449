export const Capabilities = {
  SuperAdmin: -1,
  // User Starts From 0
  User: {
    View: {
      id: 0,
      label: 'View User Data',
      description: 'The ability to view user data.'
    },
    Create: {
      id: 1,
      label: 'Create User',
      description: 'The ability to create users.'
    },
    Update: {
      id: 2,
      label: 'Update User Data',
      description: 'The ability to update user data.'
    },
    Remove: {
      id: 3,
      label: 'Remove User',
      description: 'The ability to remove users.'
    },
    Role: {
      id: 4,
      label: 'Manage Roles',
      description: 'The ability to assign and remove user roles.'
    }
  },
  // Role Starts From 100
  Role: {
    View: {
      id: 100,
      label: 'View Role',
      description: 'The ability to view role data.'
    },
    Create: {
      id: 101,
      label: 'Create Role',
      description: 'The ability to create roles.'
    },
    Update: {
      id: 102,
      label: 'Update Role Data',
      description: 'The ability to update role data.'
    },
    Remove: {
      id: 103,
      label: 'Remove Role',
      description: 'The ability to remove roles.'
    },
  },
  // Environment Starts From 200
  Environment: {
    View: {
      id: 200,
      label: 'View Environment',
      description: 'The ability to view environment data.'
    },
    Create: {
      id: 201,
      label: 'Create Environment',
      description: 'The ability to create environments.'
    },
    Update: {
      id: 202,
      label: 'Update Environment Data',
      description: 'The ability to update environment data.'
    },
    Remove: {
      id: 203,
      label: 'Remove Environment',
      description: 'The ability to remove environment.'
    },
  },
  GameAccount: {
    View: {
      id: 300,
      label: 'View Game Account',
      description: 'The ability to view a game account.'
    },
    RequestUpdate: {
      id: 301,
      label: 'Request Update Game Account',
      description: 'The ability to request to update a game account.'
    },
    Update: {
      id: 302,
      label: 'Update Game Account',
      description: 'The ability to update a game account.'
    },
    RequestBan: {
      id: 303,
      label: 'Request Update Game Account',
      description: 'The ability to request to ban a game account.'
    },
    Ban: {
      id: 304,
      label: 'Ban Game Account',
      description: 'The ability to ban a game account.',
    },
    RequestUnban: {
      id: 305,
      label: 'Request Update Game Account',
      description: 'The ability to request to unban a game account.'
    },
    Unban: {
      id: 306,
      label: 'Unban Game Account',
      description: 'The ability to unban a game account.'
    },
    RequestWhitelist: {
      id: 307,
      label: 'Request Whitelist Game Account',
      description: 'The ability to request to whitelist a game account.'
    },
    Whitelist: {
      id: 308,
      label: 'Whitelist Game Account',
      description: 'The ability to whitelist a game account.'
    },
    RequestUnwhitelist: {
      id: 309,
      label: 'Request Unwhitelist Game Account',
      description: 'The ability to request to unwhitelist a game account.'
    },
    Unwhitelist: {
      id: 310,
      label: 'Unwhitelist Game Account',
      description: 'The ability to unwhitelist a game account.'
    },
    GDPR: {
      id: 311,
      label: 'GDPR Scrub Game Account',
      description: 'The ability to scrub game account data.'
    }
  },
  CreateAccount: {
    View: {
      id: 400,
      label: 'View GD Create Accounts',
      description: 'The ability to view a gd create account.'
    },
    Approve: {
      id: 401,
      label: 'Approve GD Create Account Request',
      description: 'The ability to approve a GD Create Account request.'
    },
    Update: {
      id: 402,
      label: 'Reject GD Create Account Request',
      description: 'The ability to reject a GD Create Account request'
    },
    TogglePayout: {
      id: 403,
      label: 'Toggle GD Create Account Payout Settings',
      description: 'The ability to toggle and update a GD Create Account payout settings.'
    },
    PayoutRequest: {
      id: 404,
      label: 'View, Approve and Reject Payout Requests',
      description: 'The ability to view, approve and reject payout requests.'
    },
  },
  Configuration: {
    View: {
      id: 500,
      label: 'View Configuration',
      description: 'The ability to view a configuration.'
    },
    Create: {
      id: 501,
      label: 'Create Configuration',
      description: 'The ability to create a configuration.'
    },
    Update: {
      id: 502,
      label: 'Update Configuration',
      description: 'The ability to update a configuration.'
    },
    Delete: {
      id: 503,
      label: 'Delete Configuration',
      description: 'The ability to delete a configuration.'
    },
    Revert: {
      id: 504,
      label: 'Revert Configuration',
      description: 'The ability to revert a configuration from its history.'
    }
  }
};
