import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { useEnvironment } from '../../hooks/use-environment';
import { Capabilities } from '../../utils/capabilities';

export const CapabilityGuard = (props) => {
  const { children, capability, redirect } = props;
  const { environment } = useEnvironment();
  const { user } = useAuth();
  const capabilitiesJson = JSON.parse(localStorage.getItem('gdg_capabilities'));
  // Global Capabilties has 0 Index for the Environment
  let globalCapabilities;
  if (capabilitiesJson) globalCapabilities = capabilitiesJson['0'];

  // Return Nothing if no Environment
  // if (environment === null || typeof environment === 'undefined') return <></>;

  let capabilities;
  if (environment !== null && typeof environment !== 'undefined') capabilities = capabilitiesJson[environment.id];
  if (capabilities !== null && typeof capabilities !== 'undefined') capabilities = capabilities.concat(capabilitiesJson[0]);
  else if (globalCapabilities !== null && typeof globalCapabilities !== 'undefined') capabilities = [...globalCapabilities];

  if (capabilities === null || capability === null || typeof capability === 'undefined') return <>{children}</>;
  let capable;

  if (capability.or || capability.and) {
    // And Actions
    if (Array.isArray(capability.and)) {
      const actions = capability.and;
      capable = true;
      // Check And
      actions.forEach((action) => {
        const { id } = action;
        if (!capabilities.includes(id)) capable = false;
      });
    }
    // Or Actions
    if (Array.isArray(capability.or)) {
      const actions = capability.or;
      capable = false;
      // Check Or
      actions.forEach((action) => {
        const { id } = action;
        if (capabilities.includes(id)) capable = true;
      });
    }
  } else {
    const capabilityId = capability.id;
    capable = capabilities?.includes(capabilityId);
  }

  // Super User
  if (capability === Capabilities.SuperAdmin) {
    if (user.isSuperAdmin === true) capable = true;
    else capable = false;
  }

  // Check If User is Capable
  if (!capable && redirect) {
    return <Navigate to="/dashboard/" />;
  }
  if (!capable && !redirect) {
    return <></>;
  }

  return <>{children}</>;
};

CapabilityGuard.defaultProps = {
  redirect: false
};

CapabilityGuard.propTypes = {
  children: PropTypes.node,
  capability: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
  redirect: PropTypes.bool
};
