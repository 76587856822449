import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from './components/guards/auth-guard';
import { GuestGuard } from './components/guards/guest-guard';
import { EnvironmentGuard } from './components/guards/environment-guard';
import { LoadingScreen } from './components/loading-screen';
import { Account } from './containers/account';
import { DashboardLayout } from './containers/dashboard-layout';
import { EmptyLayout } from './containers/empty-layout';
import { CapabilityGuard } from './components/guards/capability-guard';
import { Capabilities } from './utils/capabilities';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./containers/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/password-reset').then((module) => ({ default: module.PasswordReset }))));
const VerifyCode = Loadable(lazy(() => import('./containers/verify-code').then((module) => ({ default: module.VerifyCode }))));

// Dashboard pages
const EnvironmentDashboardContainer = Loadable(lazy(() => import('./containers/environment-dashboard-container').then((module) => ({ default: module.EnvironmentDashboardContainer }))));
const EnvironmentDashboard = Loadable(lazy(() => import('./containers/environment-dashboard').then((module) => ({ default: module.EnvironmentDashboard }))));
const EnvironmentSettings = Loadable(lazy(() => import('./containers/environment-settings').then((module) => ({ default: module.EnvironmentSettings }))));
const EnvironmentApi = Loadable(lazy(() => import('./containers/environment-api').then((module) => ({ default: module.EnvironmentApi }))));
const EnvironmentSelector = Loadable(lazy(() => import('./containers/environment-selector').then((module) => ({ default: module.EnvironmentSelector }))));
const EnvironmentCreate = Loadable(lazy(() => import('./containers/environment-create').then((module) => ({ default: module.EnvironmentCreate }))));

// Configuration
const Configurations = Loadable(lazy(() => import('./containers/configurations').then((module) => ({ default: module.Configurations }))));
const ConfigurationCreate = Loadable(lazy(() => import('./containers/configuration-create').then((module) => ({ default: module.ConfigurationCreate }))));
const ConfigurationView = Loadable(lazy(() => import('./containers/configuration-view').then((module) => ({ default: module.ConfigurationView }))));
const ConfigurationSummary = Loadable(lazy(() => import('./containers/configuration-summary').then((module) => ({ default: module.ConfigurationSummary }))));
const ConfigurationActivity = Loadable(lazy(() => import('./containers/configuration-activity').then((module) => ({ default: module.ConfigurationActivity }))));
const ConfigurationContent = Loadable(lazy(() => import('./containers/configuration-content').then((module) => ({ default: module.ConfigurationContent }))));

// Servers
const Servers = Loadable(lazy(() => import('./containers/servers').then((module) => ({ default: module.Servers }))));
const ServerView = Loadable(lazy(() => import('./containers/server-view').then((module) => ({ default: module.ServerView }))));
const ServerSummary = Loadable(lazy(() => import('./containers/server-summary').then((module) => ({ default: module.ServerSummary }))));
const ServerGraphs = Loadable(lazy(() => import('./containers/server-graphs').then((module) => ({ default: module.ServerGraphs }))));
const ServerActivity = Loadable(lazy(() => import('./containers/server-activity').then((module) => ({ default: module.ServerActivity }))));
const ServerIssues = Loadable(lazy(() => import('./containers/server-issues').then((module) => ({ default: module.ServerIssues }))));

// Team
const TeamContainer = Loadable(lazy(() => import('./containers/team-container').then((module) => ({ default: module.TeamContainer }))));
const Users = Loadable(lazy(() => import('./containers/users').then((module) => ({ default: module.Users }))));
const UserCreate = Loadable(lazy(() => import('./containers/user-create').then((module) => ({ default: module.UserCreate }))));
const UserView = Loadable(lazy(() => import('./containers/user-view').then((module) => ({ default: module.UserView }))));
const UserActivity = Loadable(lazy(() => import('./containers/user-activity').then((module) => ({ default: module.UserActivity }))));
const UserSummary = Loadable(lazy(() => import('./containers/user-summary').then((module) => ({ default: module.UserSummary }))));
const UserRoles = Loadable(lazy(() => import('./containers/user-roles').then((module) => ({ default: module.UserRoles }))));
const UserEdit = Loadable(lazy(() => import('./containers/user-edit').then((module) => ({ default: module.UserEdit }))));

// Game Account
const GameAccounts = Loadable(lazy(() => import('./containers/game-accounts').then((module) => ({ default: module.GameAccounts }))));
const GameAccountView = Loadable(lazy(() => import('./containers/game-account-view').then((module) => ({ default: module.GameAccountView }))));
const GameAccountSummary = Loadable(lazy(() => import('./containers/game-account-summary').then((module) => ({ default: module.GameAccountSummary }))));
const GameAccountActivity = Loadable(lazy(() => import('./containers/game-account-activity').then((module) => ({ default: module.GameAccountActivity }))));
const GameAccountRequests = Loadable(lazy(() => import('./containers/game-account-requests').then((module) => ({ default: module.GameAccountRequests }))));

// Action Requests
const ActionRequests = Loadable(lazy(() => import('./containers/action-requests').then((module) => ({ default: module.ActionRequests }))));

const Roles = Loadable(lazy(() => import('./containers/roles').then((module) => ({ default: module.Roles }))));
const RoleCreate = Loadable(lazy(() => import('./containers/role-create').then((module) => ({ default: module.RoleCreate }))));
const RoleView = Loadable(lazy(() => import('./containers/role-view').then((module) => ({ default: module.RoleView }))));
const RoleSummary = Loadable(lazy(() => import('./containers/role-summary').then((module) => ({ default: module.RoleSummary }))));
const RoleCapabilities = Loadable(lazy(() => import('./containers/role-capabilities').then((module) => ({ default: module.RoleCapabilities }))));
const RoleActivity = Loadable(lazy(() => import('./containers/role-activity').then((module) => ({ default: module.RoleActivity }))));

// GD Create
const GDCreateAccounts = Loadable(lazy(() => import('./containers/gdcreate-accounts').then((module) => ({ default: module.GDCreateAccounts }))));
const GDCreateAccountView = Loadable(lazy(() => import('./containers/gdcreate-account-view').then((module) => ({ default: module.GDCreateAccountView }))));
const GDCreateAccountSummary = Loadable(lazy(() => import('./containers/gdcreate-account-summary').then((module) => ({ default: module.GDCreateAccountSummary }))));
const GDCreateAccountActivity = Loadable(lazy(() => import('./containers/gdcreate-account-activity').then((module) => ({ default: module.GDCreateAccountActivity }))));
const GDCreateAccountTransactions = Loadable(lazy(() => import('./containers/gdcreate-account-transactions').then((module) => ({ default: module.GDCreateAccountTransactions }))));
const GDCreateAccountRequests = Loadable(lazy(() => import('./containers/gdcreate-account-requests').then((module) => ({ default: module.GDCreateAccountRequests }))));
const GDCreatePayoutRequests = Loadable(lazy(() => import('./containers/gdcreate-payout-requests').then((module) => ({ default: module.GDCreatePayoutRequests }))));

const AccountGeneral = Loadable(lazy(() => import('./containers/account-general').then((module) => ({ default: module.AccountGeneral }))));
const AccountNotifications = Loadable(lazy(() => import('./containers/account-notifications').then((module) => ({ default: module.AccountNotifications }))));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: '/environment',
    element: (
      <AuthGuard>
        <EmptyLayout>
          <EnvironmentDashboardContainer />
        </EmptyLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: '/selector',
        element: <EnvironmentSelector />
      },
      {
        path: '/create',
        element: (
          <CapabilityGuard
            capability={Capabilities.Environment.Create}
            redirect
          >
            <EnvironmentCreate />
          </CapabilityGuard>
        )
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <EnvironmentGuard>
          <DashboardLayout />
        </EnvironmentGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: ':environmentId',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="environment"
                replace
              />
            )
          },
          {
            path: '/environment',
            element: <EnvironmentDashboardContainer />,
            children: [
              {
                path: '/',
                element: <EnvironmentDashboard />
              },
              {
                path: '/settings',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.Update}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentSettings />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: '/settings',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.Update}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentSettings />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: '/advanced',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.View}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentApi />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
            ]
          },
          {
            path: '/team',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/users',
                children: [
                  {
                    path: '/',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <Users status />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: '/disabled',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <Users status={false} />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: '/create',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.Create}
                        redirect
                      >
                        <UserCreate />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: ':userId',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <UserView />
                      </CapabilityGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <UserSummary />
                      },
                      {
                        path: 'activity',
                        element: <UserActivity />
                      },
                      {
                        path: 'roles',
                        element: <UserRoles />
                      },
                      {
                        path: 'edit',
                        element: (
                          <CapabilityGuard
                            capability={Capabilities.User.Update}
                            redirect
                          >
                            <UserEdit />
                          </CapabilityGuard>
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: '/roles',
                children: [
                  {
                    path: '/',
                    element: <Roles />
                  },
                  {
                    path: '/create',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Role.Create}
                        redirect
                      >
                        <RoleCreate />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: ':roleId',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Role.Update}
                        redirect
                      >
                        <RoleView />
                      </CapabilityGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <RoleSummary />
                      },
                      {
                        path: 'activity',
                        element: <RoleActivity />
                      },
                      {
                        path: 'capabilities',
                        element: <RoleCapabilities />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '/game-servers',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.View}
                    redirect
                  >
                    <EnvironmentGuard>
                      <Servers />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: ':serverId',
                element: (
                  <ServerView />
                ),
                children: [
                  {
                    path: '/',
                    element: (
                      <ServerSummary />
                    ),
                  },
                  {
                    path: '/graphs',
                    element: (
                      <ServerGraphs />
                    )
                  },
                  {
                    path: '/activity',
                    element: (
                      <ServerActivity />
                    )
                  },
                  {
                    path: '/issues',
                    element: (
                      <ServerIssues />
                    )
                  }
                ]
              },
              {
                path: '/configurations',
                children: [
                  {
                    path: '/',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Environment.View}
                        redirect
                      >
                        <EnvironmentGuard>
                          <Configurations />
                        </EnvironmentGuard>
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: 'create',
                    element: (
                      <ConfigurationCreate />
                    )
                  },
                  {
                    path: ':configurationId',
                    element: (
                      <ConfigurationView />
                    ),
                    children: [
                      {
                        path: '/',
                        element: <ConfigurationSummary />
                      },
                      {
                        path: 'activity',
                        element: <ConfigurationActivity />
                      },
                      {
                        path: 'content',
                        element: <ConfigurationContent />
                      }
                    ]
                  }
                ]
              },
            ]
          },
          {
            path: '/game-accounts',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: <GameAccounts />
              },
              {
                path: '/search/:query',
                element: <GameAccounts />
              },
              {
                path: '/view/:accountId',
                element: <GameAccountView />,
                children: [
                  {
                    path: '/',
                    element: <GameAccountSummary />
                  },
                  {
                    path: 'activity',
                    element: <GameAccountActivity />
                  },
                  {
                    path: 'requests',
                    element: <GameAccountRequests />
                  }
                ]
              },
            ]
          },
          {
            path: 'action-requests',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: <ActionRequests />
              }
            ]
          },
          {
            path: 'gd-create',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/requests',
                element: <GDCreateAccountRequests />
              },
              {
                path: '/payout',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.CreateAccount.PayoutRequest}
                    redirect
                  >
                    <GDCreatePayoutRequests />
                  </CapabilityGuard>
                )
              },
              {
                path: '/accounts',
                children: [
                  {
                    path: '/',
                    element: <GDCreateAccounts />
                  },
                  {
                    path: ':gdCreateAccountId',
                    element: <GDCreateAccountView />,
                    children: [
                      {
                        path: '/',
                        element: <GDCreateAccountSummary />
                      },
                      {
                        path: '/activity',
                        element: <GDCreateAccountActivity />
                      },
                      {
                        path: '/transactions',
                        element: <GDCreateAccountTransactions />
                      }
                    ]
                  },
                ]
              },
            ]
          },
          {
            path: 'account',
            element: <Account />,
            children: [
              {
                path: '/',
                element: <AccountGeneral />
              },
              {
                path: 'notifications',
                element: <AccountNotifications />
              }
            ]
          },
          {
            path: '*',
            element: <NotFound />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: (
      <DashboardLayout>
        <NotFound />
      </DashboardLayout>
    )
  }
];

export default routes;
